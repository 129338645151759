<template>
    <div class="echarts-container">
        <div class="echarts" ref="echarts"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    data() {
        return {};
    },
    mounted() {
        this.initCharts();
    },
    methods: {
        initCharts() {
            const option = {
                tooltip: {
                    trigger: "axis",
                    showContent: false,
                },
                legend: {
                    show: true,
                    data: [
                        {
                            name: "烟商专案",
                            icon: "rect",
                            itemStyle: { color: "#5B8FF9" },
                        },
                        {
                            name: "经营流水",
                            icon: "rect",
                            itemStyle: { color: "#F7E607" },
                        },
                        {
                            name: "餐饮专案",
                            icon: "rect",
                            itemStyle: { color: "#5D7092" },
                        },
                        {
                            name: "芝麻店员",
                            icon: "rect",
                            itemStyle: { color: "#E8684A" },
                        },
                        {
                            name: "芝麻店主",
                            icon: "rect",
                            itemStyle: { color: "#F6BD16" },
                        },
                        {
                            name: "通用店主",
                            icon: "rect",
                            itemStyle: { color: "#5D7092" },
                        },
                        {
                            name: "通用店员",
                            icon: "rect",
                            itemStyle: { color: "#6DC8EC" },
                        },
                        {
                            name: "其他筒子",
                            icon: "rect",
                            itemStyle: { color: "#9270CA" },
                        },
                    ],
                    textStyle: {
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular, PingFang SC",
                        fontSize: 12,
                        lineHeight: 12,
                        color: "#8C8C8C",
                        padding: [0, 20, 0, 0],
                    },
                    left: 20,
                    right: 20,
                    itemGap: 10,
                    itemHeight: 5,
                    itemWidth: 15,
                },
                grid: {
                    show: false,
                    top: "50",
                    left: "15",
                    bottom: "0",
                    right: "26",
                    containLabel: true,
                    borderWidth: "1",
                    borderColor: "rgba(0, 0, 0, 0.15)",
                },
                toolbox: {
                    show: false,
                    feature: {
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    name: "(月)",
                    type: "category",
                    boundaryGap: true,
                    offset: 0,
                    data: [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                    ],
                    axisLabel: {
                        color: "rgba(0, 0, 0, 0.45)",
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular, PingFang SC",
                        fontSize: 12,
                        lineHeight: 12,
                        nameGap: 3,
                        /* formatter: function (value, index) {
                            const month = value == 12 ? value + "1" : value;
                            return month;
                        }, */
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(0, 0, 0, 0.45)",
                        },
                    },
                    axisTick: {
                        length: 2,
                        alignWithLabel: true,
                        lineStyle: {
                            color: "rgba(0, 0, 0, 0.45)",
                        },
                    },
                    // boundaryGap: ["20%", "20%"],
                },
                yAxis: {
                    show: true,
                    name: "元",
                    data: [0, 5, 1, 15, 2],
                    type: "value",
                    boundaryGap: true,
                    axisLabel: {
                        color: "rgba(0, 0, 0, 0.45)",
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular, PingFang SC",
                        fontSize: 12,
                        lineHeight: 12,
                        nameGap: 3,
                        formatter: function (value, index) {
                            return value / 10;
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(0, 0, 0, 0.08)",
                        },
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitNumber: 4,
                    max: function (value) {
                        return value.max + 60;
                    },
                },
                axisPointer: {
                    lineStyle: {
                        type: "solid",
                        color: "rgba(171, 171, 171, 0.5)",
                    },
                },
                series: [
                    {
                        name: "餐饮专案",
                        type: "line",
                        symbol: "circle",
                        symbolSize: 3,
                        data: [101, 357, 412, 309, 28, 43, 10, 504],
                        lineStyle: {
                            normal: {
                                color: "#9270CA",
                                width: 1,
                            },
                        },
                        itemStyle: {
                            mormal: {
                                borderWidth: 0,
                                borderType: "solid",
                            },
                        },
                        select: false,
                    },
                    {
                        name: "经营流水",
                        type: "line",
                        data: [],
                        data: [102, 17, 412, 309, 28, 43, 10, 504],
                        triggerLineEvent: true,
                    },
                    {
                        name: "芝麻店主",
                        type: "line",
                        data: [10, 217, 212, 309, 28, 43, 10, 204],
                        triggerLineEvent: true,
                    },
                    {
                        name: "烟商专案",
                        type: "line",
                        data: [100, 157, 352, 302, 28, 43, 10, 504],
                    },
                    {
                        name: "芝麻店员",
                        type: "line",
                        data: [101, 757, 451, 309, 28, 43, 10, 504],
                    },
                    {
                        name: "通用店主",
                        type: "line",
                        data: [109, 357, 412, 109, 8, 23, 19, 204],
                    },
                ],
            };
            const myChart = echarts.init(this.$refs.echarts, null, {
                width: "auto",
                height: "200",
                useDirtyRect: true,
                renderer: "svg",
            });
            myChart.setOption(option);
        },
    },
};
</script>

<style lang="scss" scoped>
.echarts-container {
    .echarts {
        width: 100%;
        height: 800px;
    }
}
</style>
